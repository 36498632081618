.App {
  text-align: left;
}

.section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-width {
  margin: 0 auto;
  width: 80vw;
  max-width: 1000px;
}

.section-title {
  font-size: 40px;
}

.hero-image {
  width: 100%;
}

.landing-page-background {
  background-image: url('./assets/heroBackground.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 100vh;
  flex-direction: column;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.hamburger-menu {
  display: none;
}

.hamburger-menu .line {
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
}

.mobile-items {
  display: none;
}

.mobile-menu-container {
  display: flex;
  flex-direction: column;
}

.nav-bar img {
  height: 20px;
}

.nav-bar a {
  color: white;
  text-decoration: none;
  margin: 0 2vw;
  text-transform: uppercase;
}

.nav-bar .music-link {
  margin: 0 10px;
}

.fake-nav-bar {
  height: 60px;
}

.music-page {
  flex-wrap: wrap;
  padding-bottom: 150px;
}

.music-page.section {
  justify-content: center;
}

.music-card {
  margin: 10px;
  position: relative;
}

.music-card-hide {
  position: absolute;
  background-color: rgba(255, 0, 0, 0.3);
  top:0;
  left:0;
  width:100%;
  height:100%;
  color:black;
  text-align: center;
}

.music-card-album-cover.desktop {
  display: block;
}

.music-card-album-cover.mobile {
  display: none;
}

.music-card p {
  margin-top: 0px;
}

.music-card h2 {
  margin: 8px 0;
}

.music-card-album-cover {
  width: 20vw;
  max-width: 200px;
  border-radius: 10px;
}

.music-card .music-link img{
  height: 20px;
  padding: 5px;
}

.about-artist-content {
  padding-left: 30px;
}

.about-page-background img {
  width: 50%;
  border-radius: 10px;
}

.merch-page {
  display: flex;
  flex-direction: column;
}

.merch-card {
  padding: 25px 1vw;
}

.merch-card img {
  max-width: 300px;
  width: 24vw;
  border-radius: 10px;
}

.merch-page .section-title {
  margin-top: 100px;
  margin-bottom: 50px;
}

.merch-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.merch-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.merch-card-button {
  width: 100%;
  margin-top: 10px;
  padding: 12px;
  border-radius: 10px;
  background-color: #3e3e3e;
}

.merch-card-button.sold {
  color: white;
  border: none;
  border: 1px solid #3e3e3e;
}

.merch-card-button.buy {
  border: 1px solid #B9E28C;
  color: #B9E28C;
}

.tour-page {
  justify-content: center;
}

.tour-page .section-title {
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: center;
}

.footer {
  text-align: center;
  margin: 150px auto 50px auto;
  vertical-align: middle;
}

.footer-left {
  width: 33%;
  display: inline-block;
  text-align: left;
}

.footer-left h3 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 2px;
  margin: 8px 0;
}

.footer-middle {
  width: 33%;
  display: inline-block;
}

.footer-middle img {
  height: 40px;
}

.footer-right {
  width: 33%;
  display: inline-block;
  text-align: right;
}

.footer-right .music-link img {
  height: 24px;
  padding: 0 10px;
}

@media (max-width: 1000px) {
  .main-width {
    width: 90vw;
  }
}

@media only screen and (max-width: 767px) {
  .main-width {
    width: 100vw;
  }

  .hamburger-menu {
    display: block;
    margin-left: auto;
    margin-right: 20px;
  }

  .mobile-items {
    display: flex;
    flex-direction: column;
    margin-left: -20px;
    position: absolute;
    margin-top: 30px;
  }

  a.mobile-menu-links {
    margin: 10px 0;
  }

  .desktop-items {
    display: none;
  }

  .nav-bar img {
    margin-left: 20px;
  }

  .landing-page-background {
    background-image: url('./assets/heroBackground_mobile.png');
  }

  .music-card-album-cover {
    width: 35vw;
    max-width: none;
  }

  .about-artist-content {
    padding: 0;
  }

  .about-page-background img {
    width: 70vw;
  }

  .about-page-background.section {
    flex-direction: column;
    width: 70vw;
  }

  .merch-card img {
    width: 30vw;
  }

  .merch-card-content {
    display: block;
  }

  .merch-card-title {
    margin: 10px 0 0 0;
  }

  .merch-card-price {
    margin: 0 0 10px 0;
  }

  .footer-left {
    width: 50%;
    padding-left: 20px;
    box-sizing: border-box;
  }

  .footer-middle {
    display: none;
  }

  .footer-right {
    width: 50%;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 480px) {
  .music-card {
    background-color: rgb(255,255,255,.25);
    margin-bottom: 30px;
    border-radius: 10px;
    padding: 10px;
  }

  .music-card-album-cover {
    width: 80vw;
    height: 80px;
  }

  .music-card-album-cover.desktop {
    display: none;
  }

  .music-card-album-cover.mobile {
    display: block;
  }

  .music-card div {
    text-align: right;
  }

  .music-card p {
    margin: 0;
  }

  .merch-cards-container {
    justify-content: center;
  }

  .merch-card img {
    width: 90vw;
  }

  .merch-card-content {
    display: flex;
  }

  .merch-card-title {
    margin: 10px 0;
  }

  .merch-card-price {
    margin: 10px 0;
  }

  .footer-left {
    width: 100%;
    text-align: center;
  }

  .footer-right {
    display: none;
  }
}
